import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { Layout1Component } from "./layout/layouts/layout-1/layout.component";
import { Layout2Component } from "./layout/layouts/layout-2/layout.component";
import { AuthGuard } from "./helpers/auth-guard";

const routes: Routes = [
  {
    path: "",
    component: Layout1Component,
    children: [
      // Home
      {
        path: "",
        redirectTo: "home",
        pathMatch: "full",
      },
      {
        path: "home",
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
      },
      {
        path: "gruposopciones",
        loadChildren:
          () => import('./pages/grupos-opciones/grupos-opciones.module').then(m => m.GruposOpcionesModule),
      },
      {
        path: "grupoopcion",
        loadChildren:
          () => import('./pages/grupo-opcion/grupo-opcion.module').then(m => m.GrupoOpcionModule),
      },
      {
        path: "grupoopcion/:id",
        loadChildren:
          () => import('./pages/grupo-opcion/grupo-opcion.module').then(m => m.GrupoOpcionModule),
      },
      {
        path: "opciones",
        loadChildren: () => import('./pages/opciones/opciones.module').then(m => m.OpcionesModule),
      },
      {
        path: "opcion",
        loadChildren: () => import('./pages/opcion/opcion.module').then(m => m.OpcionModule),
      },
      {
        path: "opcion/:id",
        loadChildren: () => import('./pages/opcion/opcion.module').then(m => m.OpcionModule),
      },
      {
        path: "usuarios",
        loadChildren: () => import('./pages/usuarios/usuarios.module').then(m => m.UsuariosModule),
      },
      {
        path: "usuario",
        loadChildren: () => import('./pages/usuario/usuario.module').then(m => m.UsuarioModule),
      },
      {
        path: "usuario/:id",
        loadChildren: () => import('./pages/usuario/usuario.module').then(m => m.UsuarioModule),
      },
      {
        path: "roles",
        loadChildren: () => import('./pages/seguridad/roles/roles.module').then(m => m.RolesModule),
      },
      {
        path: "rol",
        loadChildren: () => import('./pages/seguridad/rol/rol.module').then(m => m.RolModule),
      },
      {
        path: "rol/:id",
        loadChildren: () => import('./pages/seguridad/rol/rol.module').then(m => m.RolModule),
      },
      {
        path: "rolesopciones",
        loadChildren:
          () => import('./pages/seguridad/roles-opciones/roles-opciones.module').then(m => m.RolesOpcionesModule),
      },
      {
        path: "rolescentroscostos",
        loadChildren:
          () => import('./pages/seguridad/roles-centros-costos/roles-centros-costos.module').then(m => m.RolesCentrosCostosModule),
      },

      /****************************************
                  Ahorros
      ****************************************/
      {
        path: "enviarcorreoextracto",
        loadChildren:
          () => import('./pages/ahorros/enviar-correo-extracto/enviar-correo-extracto.module').then(m => m.EnviarCorreoExtractoModule),
      },      
      /******Fin Ahorros ************** */

      /****************************************
                  Contabilidad
      ****************************************/
      {
        path: "tiposDocumentos",
        loadChildren:
          () => import('./pages/tipos-documentos/tipos-documentos.module').then(m => m.TiposDocumentosModule),
      },
      {
        path: "tipoDocumento",
        loadChildren:
          () => import('./pages/tipo-documento/tipo-documento.module').then(m => m.TipoDocumentoModule),
      },
      {
        path: "tipoDocumento/:id",
        loadChildren:
          () => import('./pages/tipo-documento/tipo-documento.module').then(m => m.TipoDocumentoModule),
      },
      {
        path: "tipoComprobante",
        loadChildren:
          () => import('./pages/contabilidad/tipo-comprobante/tipo-comprobante.module').then(m => m.TipoComprobanteModule),
      },
      {
        path: "tipoComprobante/:id",
        loadChildren:
          () => import('./pages/contabilidad/tipo-comprobante/tipo-comprobante.module').then(m => m.TipoComprobanteModule),
      },
      {
        path: "tiposComprobantes",
        loadChildren:
          () => import('./pages/contabilidad/tipos-comprobantes/tipos-comprobantes.module').then(m => m.TiposComprobantesModule),
      },
      {
        path: "comprobantes/:tipo",
        loadChildren:
          () => import('./pages/contabilidad/comprobantes/comprobantes.module').then(m => m.ComprobantesModule),
      },
      {
        path: "comprobantes/:tipo/:codigo",
        loadChildren:
          () => import('./pages/contabilidad/comprobantes/comprobantes.module').then(m => m.ComprobantesModule),
      },
      {
        path: "cargamasivacomprobantes/:tipo",
        loadChildren:
          () => import('./pages/contabilidad/cargamasiva-comprobantes/cargamasiva-comprobantes.module').then(m => m.CargaMasivaComprobantesModule),
      },
      {
        path: "procesarCierre",
        loadChildren:
          () => import('./pages/contabilidad/procesar-cierre/procesar-cierre.module').then(m => m.ProcesarCierreModule),
      },
      {
        path: "enviarretefuente",
        loadChildren:
          () => import('./pages/contabilidad/enviar-retefuente/enviar-retefuente.module').then(m => m.EnviarReteFuenteModule),
      },  
      /******Fin Contabilidad ************** */
      /****************************************
                  Cartera R
      ****************************************/
      {
        path: "obligacionesr",
        loadChildren:
          () => import('./pages/carteraR/obligaciones/obligaciones.module').then(m => m.ObligacionesModule),
      },
      {
        path: "obligacionr",
        loadChildren:
          () => import('./pages/carteraR/obligacion-r/obligacion-r.module').then(m => m.ObligacionRModule),
      },
      {
        path: "obligacionr/:id",
        loadChildren:
          () => import('./pages/carteraR/obligacion-r/obligacion-r.module').then(m => m.ObligacionRModule),
      },
      {
        path: "configuracionesr",
        loadChildren:
          () => import('./pages/carteraR/configuraciones-r/configuraciones-r.module').then(m => m.ConfiguracionesRModule),
      },
      {
        path: "configuracionr",
        loadChildren:
          () => import('./pages/carteraR/configuracionr/configuracionr.module').then(m => m.ConfiguracionRModule),
      },
      {
        path: "configuracionr/:id",
        loadChildren:
          () => import('./pages/carteraR/configuracionr/configuracionr.module').then(m => m.ConfiguracionRModule),
      },
      {
        path: "itemsfinancieros",
        loadChildren:
          () => import('./pages/carteraR/items-financieros/items-financieros.module').then(m => m.ItemsFinancierosModule),
      },
      {
        path: "itemfinanciero",
        loadChildren:
          () => import('./pages/carteraR/item-financiero/item-financiero.module').then(m => m.ItemFinancieroModule),
      },
      {
        path: "itemfinanciero/:id",
        loadChildren:
          () => import('./pages/carteraR/item-financiero/item-financiero.module').then(m => m.ItemFinancieroModule),
      },
      {
        path: "solicitudes",
        loadChildren:
          () => import('./pages/carteraR/solicitudes/solicitudes.module').then(m => m.SolicitudesModule),
      },
      {
        path: "solicitud",
        loadChildren:
          () => import('./pages/carteraR/solicitud/solicitud.module').then(m => m.SolicitudModule),
      },
      {
        path: "solicitud/:id",
        loadChildren:
          () => import('./pages/carteraR/solicitud/solicitud.module').then(m => m.SolicitudModule),
      },
      {
        path: "tiposobligacion",
        loadChildren:
          () => import('./pages/carteraR/tipos-obligacion/tipos-obligacion.module').then(m => m.TiposObligacionModule),
      },
      {
        path: "tipoobligacion",
        loadChildren:
          () => import('./pages/carteraR/tipo-obligacion/tipo-obligacion.module').then(m => m.TipoObligacionModule),
      },
      {
        path: "tipoobligacion/:id",
        loadChildren:
          () => import('./pages/carteraR/tipo-obligacion/tipo-obligacion.module').then(m => m.TipoObligacionModule),
      },
      {
        path: "tiposarchivo",
        loadChildren:
          () => import('./pages/carteraR/tipos-archivo/tipos-archivo.module').then(m => m.TiposArchivoModule),
      },
      {
        path: "tipoarchivo",
        loadChildren:
          () => import('./pages/carteraR/tipo-archivo/tipo-archivo.module').then(m => m.TipoArchivoModule),
      },
      {
        path: "tipoarchivo/:id",
        loadChildren:
          () => import('./pages/carteraR/tipo-archivo/tipo-archivo.module').then(m => m.TipoArchivoModule),
      },

      {
        path: "tiposarchivotipoobligacion",
        loadChildren:
          () => import('./pages/carteraR/tipos-archivo-tipoobligacion/tipos-archivo-tipoobligacion.module').then(m => m.TiposArchivoTipoObligacionModule),
      },/**/
      {
        path: "tipoarchivotipoobligacion",
        loadChildren:
          () => import('./pages/carteraR/tipo-archivo-tipoobligacion/tipo-archivo-tipoobligacion.module').then(m => m.TipoArchivoTipoObligacionModule),
      },
      {
        path: "tipoarchivotipoobligacion/:id",
        loadChildren:
          () => import('./pages/carteraR/tipo-archivo-tipoobligacion/tipo-archivo-tipoobligacion.module').then(m => m.TipoArchivoTipoObligacionModule),
      },
    
      /******Fin Cartera R ************** */ 

    
      /****** Cartera  ************** */ 

      {
        path: "obligaciones-anuales",
        loadChildren:
          () => import('./pages/cartera/obligaciones-anuales/obligaciones-anuales.module').then(m => m.ObligacionesAnualesModule),
      },

      /****** Fin Cartera  ************** */ 
    

      {
        path: "rubros",
        loadChildren: () => import('./pages/carteraR/rubros/rubros.module').then(m => m.RubrosModule),
      },
      {
        path: "rubro",
        loadChildren: () => import('./pages/carteraR/rubro/rubro.module').then(m => m.RubroModule),
      },
      {
        path: "rubro/:id",
        loadChildren: () => import('./pages/carteraR/rubro/rubro.module').then(m => m.RubroModule),
      },
      {
        path: "pagares",
        loadChildren: () => import('./pages/carteraR/pagares/pagares.module').then(m => m.PagaresModule),
      },
      {
        path: "pagare",
        loadChildren:
          () => import('./pages/carteraR/pagare/pagare.module').then(m => m.PagareModule),
      },
      {
        path: "pagare/:id",
        loadChildren:
          () => import('./pages/carteraR/pagare/pagare.module').then(m => m.PagareModule),
      },
      
      /******Fin Cartera R ************** */
      /****************************************
                  Seguros
      ****************************************/
      {
        path: "exequiales",
        loadChildren: () => import('./pages/seguros/exequiales/exequiales.module').then(m => m.ExequialesModule),
      },
      {
        path: "exequial",
        loadChildren:
          () => import('./pages/seguros/exequial/exequial.module').then(m => m.ExequialModule),
      },
      {
        path: "exequial/:id",
        loadChildren:
          () => import('./pages/seguros/exequial/exequial.module').then(m => m.ExequialModule),
      },  
      {
        path: "planesexequiales",
        loadChildren:
          () => import('./pages/seguros/planes-exequiales/planes-exequiales.module').then(m => m.PlanesExequialesModule),
      },
      {
        path: "planexequial",
        loadChildren:
          () => import('./pages/seguros/plan-exequial/plan-exequial.module').then(m => m.PlanExequialModule),
      },
      {
        path: "planexequial/:id",
        loadChildren:
          () => import('./pages/seguros/plan-exequial/plan-exequial.module').then(m => m.PlanExequialModule),
      },
      {
        path: "planes",
        loadChildren:
          () => import('./pages/seguros/planes/planes.module').then(m => m.PlanesModule),
      },
      {
        path: "plan",
        loadChildren:
          () => import('./pages/seguros/plan/plan.module').then(m => m.PlanModule),
      },
      {
        path: "plan/:id",
        loadChildren:
          () => import('./pages/seguros/plan/plan.module').then(m => m.PlanModule),
      },
      {
        path: "coberturas",
        loadChildren:
          () => import('./pages/seguros/coberturas/coberturas.module').then(m => m.CoberturasModule), 
      },
      {
        path: "cobertura",
        loadChildren:
          () => import('./pages/seguros/cobertura/cobertura.module').then(m => m.CoberturaModule),
      },
      {
        path: "cobertura/:id",
        loadChildren:
          () => import('./pages/seguros/cobertura/cobertura.module').then(m => m.CoberturaModule),
      },
      {
        path: "configuraciones",
        loadChildren:
          () => import('./pages/seguros/configuraciones/configuraciones.module').then(m => m.ConfiguracionesModule), 
      },
      {
        path: "configuracion",
        loadChildren:
          () => import('./pages/seguros/configuracion/configuracion.module').then(m => m.ConfiguracionModule),
      },
      {
        path: "configuracion/:id",
        loadChildren:
          () => import('./pages/seguros/configuracion/configuracion.module').then(m => m.ConfiguracionModule),
      },
      /****** Fin Seguros ************** */

      /****************************************
                  Generales
      ****************************************/
      {
        path: "registrovisitas",
        loadChildren:
          () => import('./pages/generales/registro-visitas/registro-visitas.module').then(m => m.RegistroVisitasModule),
      },
      {
        path: "maestrosgenericos/:id",
        loadChildren:
          () => import('./pages/generales/controlusuariomaestros/controlusuariomaestros.module').then(m => m.ControlusuariomaestrosModule),
      },
      /****** Fin Generales ************** */

      /****************************************
                  Pagos
      ****************************************/
      {
        path: "pagos/nivelaciontipolicencia",
        loadChildren:
          () => import('./pages/pagos/nivelacion-base-tipo-licencia/nivelacion-base-tipo-licencia.module').then(m => m.NivelacionBaseTipoLicenciaModule),
          
      },
      {
        path: "cargaarchivoseguronivelacion",
        loadChildren:
          () => import('./pages/pagos/carga-archivo-seguro-nivelacion/carga-archivo-seguro-nivelacion.module').then(m => m.CargaArchivoSeguroNivelacionModule),
      },
      /****** Fin Pagos ************** */

      /****************************************
                  Nomina
      ****************************************/
      {
        path: "cargamasivacotizaciondiasempleado",
        loadChildren:
          () => import('./pages/nomina/cargamasiva-cotizacion-dias-empleado/cargamasiva-cotizacion-dias-empleado.module').then(m => m.CargaMasivaCotizacionDiasEmpleadoModule),
      },
      {
        path: "cargamasivanominamanual",
        loadChildren:
          () => import('./pages/nomina/cargamasiva-nomina-manual/cargamasiva-nomina-manual.module').then(m => m.CargamasivaNominaManualModule),
      },
      {
        path: "cargamasivahoraextraempleado",
        loadChildren:
          () => import('./pages/nomina/cargamasiva-horaextra-empleado/cargamasiva-horaextra-empleado.module').then(m => m.CargamasivaHoraextraEmpleadoModule),
      },
      {
        path: "enviarcorreocomprobantesnomina",
        loadChildren:
          () => import('./pages/nomina/enviar-correo-comprobantes-nomina/enviar-correo-comprobantes-nomina.module').then(m => m.EnviarCorreoComprobantesNominaModule),
      },
      {
        path:"configdotacion",
        loadChildren:
        () => import('./pages/nomina/config-dotacion.module').then(m => m.ConfigDotacionModule),
      },
      {
        path:"dotacionempleado",
        loadChildren:
        () => import('./pages/nomina/dotacion-empleado/dotacion-empleado.module').then(m => m.DotacionEmpleadoModule),
      },
      {
        path: "cargamasivavacacionesempleados",
        loadChildren:
          () => import('./pages/nomina/cargamasiva-novedades-vacaciones/cargamasiva-novedades-vacaciones.module').then(m => m.CargamasivaNovedadesVacacionesModule),
      },
      /****************************************
                  Tesoreria
      ****************************************/
      {
        path:"ingresorilmanual",
        loadChildren:
        () => import('./pages/tesoreria/crear-ril/crear-ril.module').then(m => m.CrearRilModule),
      },

      {
        path:"cuponesdistritales",
        loadChildren:
        () => import('./pages/tesoreria/cupones-distritales/cupones-distritales.module').then(m => m.CuponesDistritalesModule),
      },


      /****************************************
                  Reportes
      ****************************************/

      {
        path: "reportes/:stringUrl",
        loadChildren: () => import('./pages/reportes/reportes.module').then(m => m.ReportesModule),
      },

      {
        path: "reportescomprobante/:stringUrl",
        loadChildren:
          () => import('./pages/reportes-comprobante/reportes-comprobante.module').then(m => m.ReportesComprobanteModule),
      },

      {
        path: "reportescomprobanteurl/:stringUrl",
        loadChildren:
          () => import('./pages/reportes-comprobante-url/reportes-comprobante-url.module').then(m => m.ReportesComprobanteUrlModule),
      },

      /****************************************/

      {
        path: "iframe/:stringUrl",
        loadChildren: () => import('./pages/iframe/iframe.module').then(m => m.IframeModule),
      },
      /****************************************
                  Correspondencia
      ****************************************/
     {
        path: "recibida",
        loadChildren:
          () => import('./pages/correspondencia/recibida/recibida.module').then(m => m.RecibidaModule),
      },
      {
        path: "despachadas",
        loadChildren:
          () => import('./pages/correspondencia/despachadas/despachadas.module').then(m => m.DespachadasModule),
      },
      {
        path: "tramite",
        loadChildren:
          () => import('./pages/correspondencia/tramite/tramite.module').then(m => m.TramiteModule),
      },
      /****************************************
                  Fin Correspondencia
      ****************************************/
    ],
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
  },
  {
    path: "",
    component: Layout2Component,
    children: [
      {
        path: "login",
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
      },
      {
        path: "loginsucursal",
        loadChildren:
          () => import('./pages/login-sucursal/login-sucursal.module').then(m => m.LoginSucursalModule),
      },
      {
        path: "403",
        loadChildren:
          () => import('./pages/seguridad/403/cuatro-cero-tres.module').then(m => m.CuatroCeroTresModule),
      },
      
    ],
  },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    useHash: true,
    onSameUrlNavigation: "reload",
    relativeLinkResolution: 'legacy'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
 